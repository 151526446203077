import { Route, Routes } from 'react-router-dom';
import {
  BASE_PROFILE_ROUTE,
  GTomyProvider,
  LoginPage,
  NOT_FOUND_ROUTE,
  PRIVACY_POLICY_ROUTE,
  RegisterPage,
  withColumnPage,
  withPermission,
} from 'gtomy-lib';
import { CryptovoMenu } from './components/CryptovoMenu';
import { CryptovoFooter } from './components/CryptovoFooter';
import { HomePage } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';
import { Upload } from './pages/Upload';
import { View } from './pages/View';

function App() {
  return (
    <GTomyProvider MenuComponent={CryptovoMenu} FooterComponent={CryptovoFooter} displayCookies>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/upload" element={withPermission(withColumnPage(Upload))} />
        <Route path="/view" element={withColumnPage(View)} />
        <Route path="/login" element={<LoginPage showLanguage showTheme />} />
        <Route path="/register" element={<RegisterPage showLanguage showTheme />} />
        {BASE_PROFILE_ROUTE}
        {PRIVACY_POLICY_ROUTE}
        {NOT_FOUND_ROUTE}
      </Routes>
    </GTomyProvider>
  );
}

export default App;
