import {
  Button,
  ErrorState,
  FormFileInput,
  SingleFormFile,
  Typography,
  useFilestorage,
  useTranslation,
} from 'gtomy-lib';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

interface UploadForm {
  file: SingleFormFile | null;
}

export function Upload() {
  const { t } = useTranslation('cryptovo');
  const { control, handleSubmit } = useForm<UploadForm>({
    defaultValues: {
      file: null,
    },
  });
  const { uploadFile, error } = useFilestorage();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = (form: any) => {
    setSubmitting(true);
    console.log(form);
    uploadFile(form.file).then((response) => {
      if (response) {
        console.log(response);
      }
      setSubmitting(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-4 text-center">
          <Typography as="h1" size="5xl" weight="bold">
            {t('upload.title')}
          </Typography>
          <Typography as="h2" size="2xl">
            {t('upload.subtitle')}
          </Typography>
        </div>
        <FormFileInput control={control} name="file" label="File to upload" />
        <div className="text-center">
          <Button type="submit" disabled={submitting}>
            {t('upload.title')}
          </Button>
        </div>
        {error && <ErrorState error={error} />}
      </div>
    </form>
  );
}
