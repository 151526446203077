import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './main.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { addTranslationResources, initGTomyLib } from 'gtomy-lib';
import { version } from '../package.json';
import { CS } from './locales/cs';
import { EN } from './locales/en';

initGTomyLib({
  appName: 'cryptovo',
  appDisplayName: 'Cryptovo',
  authUrl: 'https://auth.gtomy.net',
  backendUrl: 'https://services.gtomy.net/other',
  storageUrl: 'https://services.gtomy.net/storage',
  sentryConfig: {
    enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: version,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    additionalTracePropagationTargets: [],
  },
  // googleConfig: {
  //   clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  //   googleMeasurementId: import.meta.env.VITE_GOOGLE_MEASUREMENT_ID,
  // },
  themes: ['dracula', 'fantasy'],
});

addTranslationResources('cryptovo', { cs: CS, en: EN });

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
