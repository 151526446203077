import { Menu, MenuItem, useTranslation } from 'gtomy-lib';
import { Link } from 'react-router-dom';

export function CryptovoMenu() {
  const { t } = useTranslation('cryptovo');

  return (
    <Menu showIcon showAuth dropdownActions={<Link to="/profile">{t('profile')}</Link>}>
      <MenuItem as={Link} to="/">
        {t('home')}
      </MenuItem>
      <MenuItem as={Link} to="/about">
        {t('about.title')}
      </MenuItem>
      <MenuItem as={Link} to="/upload">
        {t('upload.title')}
      </MenuItem>
    </Menu>
  );
}
