import { ColumnPage, Typography, useTranslation } from 'gtomy-lib';

export function AboutPage() {
  const { t } = useTranslation('cryptovo');

  return (
    <ColumnPage>
      <div className="text-center">
        <Typography as="h1" size="5xl" weight="bold">
          {t('about.title')}
        </Typography>
        <Typography as="h2" size="2xl">
          {t('about.subtitle')}
        </Typography>
      </div>
    </ColumnPage>
  );
}
