export const EN = {
  title: 'Cryptovo',
  home: 'Home',
  footer: {
    title: 'Cryptovo',
    subtitle: 'Created by GTomy, 2024',
    usefulLinks: 'Useful links',
  },
  about: {
    title: 'About Me',
    subtitle:
      "Hello, I'm GTomy and this is my website. I'm a Programmer and AI fan, this website is a place where I can show my projects.",
  },
  upload: {
    title: 'Upload',
  },
};
