export const CS = {
  title: 'Cryptovo',
  home: 'Domů',
  footer: {
    title: 'Cryptovo',
    subtitle: 'Vytvořil GTomy, 2024',
    usefulLinks: 'Užitečné odkazy',
  },
  about: {
    title: 'O mě',
    subtitle:
      'Ahoj, jsem GTomy a toto je moje webová stránka. Jsem programátor a fanoušek umělé inteligence. Tato stránka je místem, kde mohu ukázat své projekty.',
  },
  upload: {
    title: 'Nahrát',
  },
};
