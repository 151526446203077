import { Footer, FooterItem, FooterSocialItem, useTranslation } from 'gtomy-lib';
import { Link } from 'react-router-dom';

export function CryptovoFooter() {
  const { t } = useTranslation('cryptovo');

  return (
    <Footer
      height="tall"
      showSettings
      showIcon
      socialMedia={
        <>
          <FooterSocialItem type="threads" href="https://www.threads.net/@gtomyasek" />
          <FooterSocialItem type="instagram" href="https://www.instagram.com/gtomyasek" />
          <FooterSocialItem type="youtube" href="https://youtube.com/gtomy" />
        </>
      }
      links={[
        {
          header: t('footer.usefulLinks'),
          links: (
            <FooterItem as={Link} to="/privacy-policy">
              {t('privacy.title', { ns: 'pages' })}
            </FooterItem>
          ),
        },
      ]}
      title={t('footer.title')}
      subtitle={t('footer.subtitle')}
    />
  );
}
